import {Injectable} from '@angular/core';
import {combineLatest, of, Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {distinctUntilChanged, map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {CrewCvCompletionDto} from '@models/crew-cv';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class CrewCvCompletionService {

  private updateCompletion$: Subject<void> | null;
  cvCompletion$: Observable<CrewCvCompletionDto | null>;

  private readonly apiUrl = 'api/cv';

  constructor(
    private readonly http: TransferHttpService,
    private readonly appStateService: AppStateService,
  ) {
  }

  init(): void {
    this.updateCompletion$ = new Subject<void>();
    this.cvCompletion$ = combineLatest([
      this.appStateService.user$.pipe(map(user => user ? user.id : null), distinctUntilChanged()),
      this.updateCompletion$.pipe(startWith(null)),
    ]).pipe(
      switchMap(([userId]) => userId ? this.getCrewCvCompletion() : of(null)),
      shareReplay(1),
    );
  }

  updateCompletion(): void {
    if (!this.updateCompletion$) {
      return;
    }
    this.updateCompletion$.next();
  }

  destroy(): void {
    if (!this.updateCompletion$ || this.updateCompletion$.isStopped) {
      return;
    }
    this.updateCompletion$.complete();
    this.updateCompletion$ = null;
  }

  private getCrewCvCompletion(): Observable<CrewCvCompletionDto> {
    return this.http
      .get<ResponseDto<CrewCvCompletionDto>>(`${this.apiUrl}/get-completed-percent`)
      .pipe(map(response => response.data));
  }

}
